import { Form } from '@ant-design/compatible';
import { Modal, Input, Select, Radio, Checkbox } from 'antd';
import React from 'react';

const { Option } = Select;

const HubCreateForm = Form.create({ name: 'form_in_modal' })(
  // eslint-disable-next-line
  class extends React.Component {
    render() {
      const {
        visible,
        onCancel,
        onCreate,
        form,
        confirmLoading,
        cellData,
      } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          visible={visible}
          title="Add a new subscriber account"
          okText="Create"
          onCancel={onCancel}
          onOk={onCreate}
          confirmLoading={confirmLoading}
        >
          <Form layout="vertical">
            <Form.Item label="Hub ID">
              {getFieldDecorator('hubId', {
                rules: [
                  {
                    required: true,
                    message: 'Please input the Hub ID',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="MNO Account ID (MSISDN)">
              {getFieldDecorator('mnoAccountId', {
                rules: [
                  {
                    required: true,
                    message: 'Please input the MNO Account ID (MSISDN)!',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="LTE CPE ID (imsi)">
              {getFieldDecorator('imsi', {
                rules: [
                  {
                    required: true,
                    message: 'Please input the LTE CPE ID (Must be imsi)',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="ICCID">
              {getFieldDecorator('iccid', {
                rules: [
                  {
                    required: true,
                    message: 'Please input the ICCID',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="MNO Alias">
              {getFieldDecorator('mnoAlias', {
                initialValue: "xl_axiata",
                rules: [
                  {
                    required: true,
                    message: 'Please input the MNO Alias',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item label="MNO ID">
              {getFieldDecorator('mnoId', {
                initialValue: "m17641935",
                rules: [
                  {
                    required: true,
                    message: 'Please input the MNO ID',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            <Form.Item
              label="Account Status"
              className="collection-create-form_last-form-item"
            >
              {getFieldDecorator('status', {
                initialValue: 'ACTIVE',
              })(
                <Radio.Group>
                  <Radio value="ACTIVE">Active</Radio>
                  <Radio value="INACTIVE">Inactive</Radio>
                </Radio.Group>
              )}
            </Form.Item>
            <Form.Item
              label="Device Type"
              className="collection-create-form_last-form-item"
            >
              {getFieldDecorator('deviceType', {
                initialValue: 'VIRTUAL',
              })(
                <Radio.Group>
                  <Radio value="VIRTUAL">Virtual</Radio>
                  <Radio value="VIRTUAL_SIM">Virtual Sim</Radio>
                  <Radio value="REAL">Real</Radio>
                </Radio.Group>
              )}
            </Form.Item>
            <Form.Item label="Device Version">
              {getFieldDecorator('deviceVersion', {
                initialValue: "test-v0.1",
                rules: [
                  {
                    required: true,
                    message: 'Please input the Device Version',
                  },
                ],
              })(<Input />)}
            </Form.Item>
            {/* <Form.Item label="Activate Promo Subscription?">
              {getFieldDecorator('activatePromo', {
                initialValue: false,
              })(<Checkbox />)}
            </Form.Item> */}
            {/* <Form.Item
              label="Cell Id"
              className="collection-create-form_last-form-item"
            >
              {getFieldDecorator('cellId', {
                initialValue: '9900000000700',
                rules: [
                  {
                    required: true,
                    message: 'Please input the Cell ID',
                  },
                ],
              })(
                <Select>
                  {cellData.map(({ cellId }) => (
                    <Option value={cellId}>{cellId}</Option>
                  ))}
                </Select>
              )}
            </Form.Item>
            <Form.Item label="IP">
              {getFieldDecorator('cpeIp', {
                initialValue: String(Number(new Date())),
                rules: [
                  {
                    required: true,
                    message: 'Please input the CPE IP',
                  },
                ],
              })(<Input />)}
            </Form.Item> */}
            {/* <Form.Item
              label="Virtual CPE?"
              className="collection-create-form_last-form-item"
            >
              {getFieldDecorator('virtual', {
                initialValue: true,
                rules: [
                  {
                    required: true,
                    message: 'Please input Virtual',
                  },
                ],
              })(
                <Radio.Group>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              )}
            </Form.Item> */}
          </Form>
        </Modal>
      );
    }
  }
);

export default HubCreateForm;
