import { Auth } from "aws-amplify";
import axios from "axios"
import { API_URL, HEALTH_URL } from "./constants";
import moment from "moment";
import { message, notification } from "antd";
import { useHistory } from "react-router";


export const generateAuthHeader = (user) => ({
  headers: {
    "Content-Type": "application/json",
    authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
  },
});

export const generateOldAuthHeader = (user) => ({
  headers: {
    "Content-Type": "application/json",
    authorization: `Bearer ${user.attributes["custom:old_dashboard_token"]}`,
  },
});

export const parseJWT = (token) => {
  if (!token) return {};

    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

const clearStoredSidenToken = () => {
    localStorage.removeItem('sidenTokenJWT') 
    localStorage.removeItem('sidenTokenExpires')
    localStorage.removeItem('sidenTokenExp')
}


const retrieveStoredSidenToken = () => {
  const currentSidenTokenJWT = localStorage.getItem('sidenTokenJWT') 
  const currentSidenTokenExpires = localStorage.getItem('sidenTokenExpires')
  const currentSidenTokenExp = localStorage.getItem('sidenTokenExp')

  return {
    jwt: currentSidenTokenJWT, 
    expires: currentSidenTokenExpires, 
    exp: currentSidenTokenExp
  }
}

const constructStoredSidenToken = (result) => {
  localStorage.setItem("sidenTokenJWT", result.jwt)
  localStorage.setItem("sidenTokenExpires", result.expires)
  localStorage.setItem("sidenTokenExp", moment(result.expires).unix())
}

const constructStoredSidenMNOToken = (data, mnoId) => {
  console.log({data})
  localStorage.setItem("sidenMNOTokenJWT", data.jwt)
  localStorage.setItem("sidenMNOTokenExpiresAt", data.expiresAt)
  localStorage.setItem("sidenMNOTokenExp", moment(data.expiresAt).unix())
  localStorage.setItem("sidenMNOTokenMNOID", mnoId)
}

const retrieveStoredSidenMNOToken = () => {
  return {
    jwt: localStorage.getItem("sidenMNOTokenJWT"),
    exipresAt: localStorage.getItem("sidenMNOTokenExpiresAt"),
    exp: localStorage.getItem("sidenMNOTokenExp"),
    mnoId: localStorage.getItem("sidenMNOTokenMNOID"),
  }
}

const clearStoredSidenMNOToken = () => {
    localStorage.removeItem('sidenMNOTokenJWT') 
    localStorage.removeItem('sidenMNOTokenExpiresAt')
    localStorage.removeItem('sidenMNOTokenExp')
    localStorage.removeItem('sidenMNOTokenMNOID')
}

const msToTokenExpiry = (unixTime) => {
  return ((unixTime * 1000) - moment.now())
}

export const generateSidenToken = () => {
    const currentSidenToken = retrieveStoredSidenToken()

    return Auth.currentAuthenticatedUser()
      .then((user) => {
       if (!user || (msToTokenExpiry(user.signInUserSession.idToken.payload.exp) < 60000)) {
          message.error("Token Expired")
          clearStoredSidenToken()
          Auth.signOut()
        }

        if (currentSidenToken.jwt && (msToTokenExpiry(currentSidenToken.exp) > 60000)) {
          console.log("usingSidenToken")
          return currentSidenToken
        }
        return axios.get(`${API_URL}/api/v1/ams/mno-dashboard-user-login`, generateAuthHeader(user)).then(
        (result) => {
          const tokenData = result.data
          console.log("gettingSidenToken")
          constructStoredSidenToken(tokenData)
          return tokenData
        },
        (error) => {
          console.log("Siden TokenError on Retreival");
          if (error == "not authenticated") {
            clearStoredSidenToken()
            Auth.signOut()
            window.location = "/"
          }
        }
      )
      })
      .then(
        (result) => {
          return result
        },
        (error) => {
          console.log("Siden TokenError");
          console.log({error})
          clearStoredSidenToken()
          Auth.signOut()
        }
      )
};

export const generateSidenMNOToken = () => {
    const currentSidenMNOToken = retrieveStoredSidenMNOToken()

    let mnoId = localStorage.getItem("selectedMNOId")

    if (!mnoId) {
      message.info("Please select an MNO tenant to begin.")
    }

    return Auth.currentAuthenticatedUser()
      .then((user) => {
       if (!user || (msToTokenExpiry(user.signInUserSession.idToken.payload.exp) < 60000)) {
          message.error("Token Expired")
          clearStoredSidenToken()
          clearStoredSidenMNOToken()
          Auth.signOut()
        }

        if (currentSidenMNOToken.jwt && (msToTokenExpiry(currentSidenMNOToken.exp) > 60000 && currentSidenMNOToken.mnoId == mnoId)) {
          console.log("usingStoredSidenMNOToken")
          return currentSidenMNOToken
        }

        return generateSidenToken().then(sidenToken => {
          const parsedToken = parseJWT(sidenToken.jwt)

          if (parsedToken["https://siden.io/mnoId"]) {
            localStorage.setItem("selectedMNOId", parsedToken["https://siden.io/mnoId"])

            return {data: sidenToken, mnoId: parsedToken["https://siden.io/mnoId"]}
          }

          return axios.post(`${API_URL}/grpc/v1/accounts/admin/token/mno`, {mnoId}, generateSidenAuthHeader(sidenToken))
        }
        ).then(
            (result) => {
              constructStoredSidenMNOToken(result.data, result.mnoId || mnoId)
              console.log("Getting New SIDEN MNO Token")
              return retrieveStoredSidenMNOToken()
            },
            (error) => {
              message.error("Error with Siden MNO Token")
              console.log("Siden MNO TokenError1");
              console.log({error})
              // clearStoredSidenToken()
              clearStoredSidenMNOToken()
              return retrieveStoredSidenMNOToken()
              // Auth.signOut()
            }
      )
      })
      .then(
        (result) => {
          return result
        },
        (error) => {
          console.log("Siden MNO TokenError2 - Error ");
          console.log({error})
          clearStoredSidenToken()
          clearStoredSidenMNOToken()
          Auth.signOut()
        }
      )
};

export const generateSidenMNOTokenDraft = () => {
    // const currentSidenToken = retrieveStoredSidenToken()
    let mnoId = "m17641936"

    return generateSidenToken().then(sidenToken =>
      axios.post(`${API_URL}/grpc/v1/accounts/admin/token/mno`, {mnoId}, generateSidenAuthHeader(sidenToken))
    ).then(
        (result) => {
          constructStoredSidenMNOToken(result, mnoId)
          console.log("using SIDEN MNO Token")
          return retrieveStoredSidenMNOToken()
        },
        (error) => {
          console.log("Siden MNO TokenError");
          console.log({error})
          clearStoredSidenToken()
          Auth.signOut()
        }
      )
};

export const generateSidenTokenOld = () => {
    return Auth.currentAuthenticatedUser()
      .then((user) => {
       if (!user || (user.signInUserSession.idToken.payload.exp * 1000) - moment.now() < 60000) {
          message.error("Token Expired")
          Auth.signOut()
        }
        return axios.get(`${API_URL}/api/v1/ams/mno-dashboard-user-login`, generateAuthHeader(user))
      })
      .then(
        (result) => {
          const sidenToken = (result.data.jwt);
          console.log({sidenToken});
          return sidenToken
        },
        (error) => {
          console.log("Siden TokenError");
          if (error == "not authenticated") {
            Auth.signOut()
            window.location = "/"
          }
        }
      )
};

export const generateSidenAuthHeaderOld = (sidenToken) => ({
  headers: {
    "Content-Type": "application/json",
    authorization: `Bearer ${sidenToken}`,
  },
});

export const generateSidenAuthHeader = (sidenToken) => ({
  headers: {
    "Content-Type": "application/json",
    authorization: `Bearer ${sidenToken.jwt}`,
  },
});

export const getPartnerMNOs = () => {
  return generateSidenToken().then(sidenToken =>
          axios.get(`${API_URL}/grpc/v1/partner-mgmt/mno-paged?page.limit=99`, generateSidenAuthHeader(sidenToken)).then(
    (result) => {
      return result.data.configs;
    },
    (error) => {
      console.log(error);
      return [];
    }
  )
  ).catch(
    e=> {
      console.log(e);
      return [];
    }
  )};

