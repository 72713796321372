// React
import React, { useEffect, useState } from "react";

import { generateAuthHeader, generateSidenMNOToken } from "../authHeader";

import { Auth } from "aws-amplify";
import { PlusOutlined } from "@ant-design/icons";

import { Row, Button, message, notification, Result, Descriptions, Badge, Col, Divider, Progress, Table, Typography, Collapse, Spin, Tag, Form, InputNumber, Radio } from "antd";

import { BASE_URL, REACT_APP_QS_SUBSCRIBER_HUB_AND_CONTENT_ANALYTICS } from "../constants.js";
import HubCreateForm from "./HubCreateForm.js";
import SubscriberTable from "./SubscriberTable.js";

import Amplify, { API, graphqlOperation } from 'aws-amplify';
import {
  loadAccountsNew,
  createAccountNew,
  // loadAllCells,
} from "./newApiCalls.js";
import { asyncRunQuery } from "../Shared/redshift";

import gql from "graphql-tag";
import { graphql, useQuery, useMutation } from "@apollo/client";
import DataDisplay from "../Shared/DataDisplay";
import DataDisplayRange from "../Shared/DataDisplayRange";
import { useParams } from "react-router";
import { locateHubBy } from "../Devices/newApiCalls";
import { Link } from "react-router-dom";
import QSDashboardGeneric from "../QSDashboardGeneric";
import moment from "moment"
import { deleteSCEConfigForHub, deleteUIConfigForHub, loadAllSCEConfigs, loadAllUIConfigs, loadSCEConfigForHub, loadUIConfigForHub,mapSCEConfigForHub,mapUIConfigForHub } from "../HubConfigs/newApiCalls";
import ReactJson from "react-json-view";
import Modal from "antd/lib/modal/Modal";

const axios = require("axios");

const {Item} = Descriptions
const {Panel} = Collapse;


const { Title } = Typography;

const columns = [
  {
    title: 'env',
    dataIndex: 'env',
    key: 'env',
  },
  {
    title: 'hubId',
    dataIndex: 'hubId',
    key: 'hubId',
  },
  {
    title: 'timestamp',
    dataIndex: 'timestamp',
    key: 'timestamp',
  },
  {
    title: 'errorType',
    dataIndex: 'errorType',
    key: 'errorType',
  },
  {
    title: 'errorMsg',
    dataIndex: 'errorMsg',
    key: 'errorMsg',
  },
  {
    title: 'serverTime',
    dataIndex: 'serverTime',
    key: 'serverTime',
  },
];

const columns1 = [
  {
    title: 'env',
    dataIndex: 'env',
    key: 'env',
  },
  {
    title: 'hubId',
    dataIndex: 'hubId',
    key: 'hubId',
  },
  {
    title: 'timestamp',
    dataIndex: 'timestamp',
    key: 'timestamp',
  },
  {
    title: 'serverTime',
    dataIndex: 'serverTime',
    key: 'serverTime',
  },
    {
    title: 'state',
    dataIndex: 'state',
    key: 'state',
  },
];

const ModalForm = ({ formInstance, visible, onCancel, options, onFormFinish, item, confirmLoading, setConfirmLoading }) => {
  const onOk = () => {
    setConfirmLoading(true)
    formInstance.submit();
  };

  return (
    <Modal title={`Apply Custom ${item} Configuration`} visible={visible} onOk={onOk} onCancel={onCancel} okText="Submit" confirmLoading={confirmLoading}>
      <Form form={formInstance} layout="vertical" name={`${item}Form`} onFinish={onFormFinish}>
          <Form.Item
            name={"policyId"}
            label={`${item} Configuration Policy`}
            rules={[{ required: true, message: 'Please pick a policy!' }]}
          >
            <Radio.Group>
              {options.map(SCEConfig => {
               if (SCEConfig.defaultConfig) {
                 return null
               }
              return(<Radio value={SCEConfig.policyId}>{SCEConfig.policyId}</Radio>)
              })}
            </Radio.Group>
          </Form.Item>
      </Form>
    </Modal>
  );
};


const DeviceDashboard = (props) => {
  // const { loading, error, data } = useQuery(REDSHIFT_DESCRIBE_STATEMENT, {
  //   variables: { id: "127a2809-a05b-4add-afd4-d52ade4c7a9a" },
  // });

  const [hubErrorsQuery, setHubErrorsQuery] = useState({records: []});
  const [hubNetworkEventsQuery, setHubNetworkEventQuery] = useState({records: []});
  const [hubStatisticsV2Query, setHubStatisticsV2Query] = useState({records: []});
  const [hubStartQuery, setHubStartQuery] = useState({records: []});
  const [hubData, setHubData] = useState(null);
  const [SCEConfigForHub, setSCEConfigForHub] = useState({});
  // const [UIConfigForHub, setUIConfigForHub] = useState({});
  const [SCEForm] = Form.useForm();
  // const [UIForm] = Form.useForm();

  const [SCEConfigs, setSCEConfigs] = useState([])
  // const [UIConfigs, setUIConfigs] = useState([])


  const [SCEFormVisible, setSCEFormVisible] = useState(false);
  const [SCEConfirmLoading, setSCEConfirmLoading] = useState(false);
  const { hubId } = useParams();

  const showSCEFormModal = () => {
    setSCEFormVisible(true);
  };
  const hideSCEFormModal = () => {
    setSCEFormVisible(false);
  };

  // const [UIFormVisible, setUIFormVisible] = useState(false);
  // const [UIConfirmLoading, setUIConfirmLoading] = useState(false);

  // const showUIFormModal = () => {
  //   setUIFormVisible(true);
  // };

  // const hideUIFormModal = () => {
  //   setUIFormVisible(false);
  // };

  const updateSCEConfig = ({policyId}) => {
    mapSCEConfigForHub(hubId, policyId).then(v=>loadSCEConfigForHub(setSCEConfigForHub, hubId)).then(v=>{
      setSCEConfirmLoading(false)
      hideSCEFormModal()
    })
  };
  const restoreSCEToDefault = () => {
    setSCEConfirmLoading(true)
    deleteSCEConfigForHub(hubId).then(v=>loadSCEConfigForHub(setSCEConfigForHub, hubId)).then(v=>{
      setSCEConfirmLoading(false)
    })
  };
  // const updateUIConfig = ({policyId}) => {
  //   mapUIConfigForHub(hubId, policyId).then(v=>loadUIConfigForHub(setUIConfigForHub, hubId)).then(v=>{
  //     setUIConfirmLoading(false)
  //     hideUIFormModal()
  //   })
  // };
  // const restoreUIToDefault = () => {
  //   setUIConfirmLoading(true)
  //   deleteUIConfigForHub(hubId).then(v=>loadUIConfigForHub(setUIConfigForHub, hubId)).then(v=>{
  //     setUIConfirmLoading(false)
  //   })
  // };


  const queryParamsforHubErrors = { queryIdentifier: "ANALYTICS_HUB_ERROR", param: hubId }
  const queryParamsforHubNetworkEvents = { queryIdentifier: "HUB_NETWORK_CHANGES", param: hubId }
  const queryParamsforHubStatisticsV2 = { queryIdentifier: "HUB_STATISTICS_V2", param: hubId }
  const queryParamsforHubStart = { queryIdentifier: "HUB_START", param: hubId }

  const dsRows = ["env", "hubId","timestamp", "errorType", "errorMsg", "serverTime"];
  const dataSource = hubErrorsQuery.records.map((record)=>{
    let datum = {}
    record.map((v,i)=> datum[dsRows[i]] = v.stringValue)
    return datum
  });
  
  const dsRows1 = ["env", "hubId","timestamp", "serverTime","state"];
  const dataSource1 = hubNetworkEventsQuery.records.map((record)=>{
    let datum = {}
    record.forEach((v,i)=> datum[dsRows1[i]] = v.stringValue)
    return datum
  });

  const hubStart = {}

  hubStartQuery.records.forEach((record, i)=>{
    record.forEach((v,j)=> hubStart[hubStartQuery.columnMetadata[j].label] = v.stringValue || v.longValue)
  })

  const hubStats = {}

  hubStatisticsV2Query.records.forEach((record, i)=>{
    record.forEach((v,j)=> hubStats[hubStatisticsV2Query.columnMetadata[j].label] = v.stringValue || v.longValue)
  })

  useEffect(() => {
    if (props.hasSidenTokenAdminRole) {
      loadAllSCEConfigs(setSCEConfigs)
      // loadAllUIConfigs(setUIConfigs)
    }
  }, [props.hasSidenTokenAdminRole])

  useEffect(() => {
    if (!hubId) {return;} 
    if (props.hasSidenTokenAdminRole) {
      loadSCEConfigForHub(setSCEConfigForHub, hubId)
      // loadUIConfigForHub(setUIConfigForHub, hubId)
    }
  }, [props.hasSidenTokenAdminRole])

//   useEffect(() => {
//     loadAllSCEConfigs(setSCEConfigs)
//   }, [])
//   useEffect(() => {
//     loadAllUIConfigs(setUIConfigs)
//   }, [])

//    useEffect(() => {
//   if (!hubId) {return;} 
//     loadSCEConfigForHub(setSCEConfigForHub, hubId)
// }, [])
//  useEffect(() => {
//   if (!hubId) {return;} 
//     loadUIConfigForHub(setUIConfigForHub, hubId)
// }, [])

 useEffect(() => {
  if (!hubId) {return;} 
  asyncRunQuery(queryParamsforHubErrors, setHubErrorsQuery)
  }, [])

 useEffect(() => {
  if (!hubId) {return;} 
  asyncRunQuery(queryParamsforHubNetworkEvents, setHubNetworkEventQuery)
}, [])

 useEffect(() => {
  if (!hubId) {return;} 
  asyncRunQuery(queryParamsforHubStatisticsV2, setHubStatisticsV2Query)
}, [])

 useEffect(() => {
  if (!hubId) {return;} 
  asyncRunQuery(queryParamsforHubStart, setHubStartQuery)
}, [])

 useEffect(() => {
  if (!hubId) {return;} 
    locateHubBy("hubId", hubId,setHubData)
}, [])

  const forceRefresh = () => {
    setHubData(null)
    if (!hubId) {return} 
    locateHubBy("hubId", hubId,setHubData)
    asyncRunQuery(queryParamsforHubStart, setHubStartQuery)
    asyncRunQuery(queryParamsforHubStart, setHubStartQuery)
    asyncRunQuery(queryParamsforHubStatisticsV2, setHubStatisticsV2Query)
    asyncRunQuery(queryParamsforHubErrors, setHubErrorsQuery)
  }

 console.log({hubErrorsQuery})
 console.log({hubNetworkEventsQuery})
 console.log({hubData})

if(!hubData) {
 return (
    <div
    >
    <Title level={2}>Device Status</Title>
        <Row>
          <Button type="primary" onClick={forceRefresh}>Refresh</Button>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <Spin size="large" />
        </Row>
    </div> 
    ) 
  }

 return (
    <div
    > 
      <Title level={2}>Device Status</Title>
        <Row>
          <Button type="primary" onClick={forceRefresh}>Refresh</Button>
        </Row>
        <Row style={{ marginTop: "10px" }}>
           <Descriptions title="Main"  bordered
            //   extra={ dataSource1.length ? 
            //   (dataSource1[0].state == "AVAILABLE" ? <Badge status="processing" text="Online" /> : <Badge status="error" text="Offline" />): <Row align="center">
            //   <Spin size="small" />
            // </Row>}
           >
      <Item label="Hub Id">{hubData.hubId}</Item>
    <Item label="ICCID">{hubData.iccid}</Item>
    <Item label="MNO Account ID (MSISDN)">{hubData.mnoAccountId}</Item>
    <Item label="Siden Account ID">
      {hubData.sidenAccountId}
    </Item>
    {/* <Item label="Siden Account ID">
      <Link to={`/${props.debugMode ? "debug_subscribers" : "subscribers"}/${hubData.sidenAccountId}`}>{hubData.sidenAccountId}</Link>
    </Item> */}
    {/* <Item label="mnoAlias">{hubData.mnoAlias}</Item> */}
    <Item label="SCE Host Name">{hubData.sceHostname}</Item>
    {props.debugMode && <Item label="Device Type">{hubData.deviceType}</Item>}
    <Item label="Device Version">{hubData.deviceVersion}</Item>
    {/* <Item label="Discount">$20.00</Item>
    <Item label="Official Receipts">$60.00</Item> */}
  </Descriptions>
          
        </Row>
        <Divider/>
        {/* <Row gutter={[16, 16]}>
         <Col span={11}>
           <Descriptions column={2} title="LTE"  bordered
              extra={hubStats.cell_id ? <Badge status="processing" text="Attached" /> : <Badge status="error" text="Not Attached" />}
           >
        
      <Item label="IP Address" span={2}></Item>
          <Item label="LTE Cell Attachement" span={2}>
      Status:   {hubStats.cell_id ? <Badge status="processing" text="Attached" /> : <Badge status="error" text="Not Attached" />}
      <br />
      Cell ID: {<Link to={`/${props.debugMode ? "debug_virtual_cells" : "cell_management"}/${hubStats.cell_id}`}>{hubStats.cell_id}</Link>}
      <br />
      Last Attachememt: {hubStats.server_time}
      <br />
      Duration: {moment(hubStats.server_time).fromNow()}
    </Item>
    <Item label="Primary Cell">
      {hubStats.cell_id}
    </Item>
    <Item label="Secondary Cell">
    </Item>
    <Item label="SINR"></Item>
    <Item label="CQI"></Item>
    <Item label="Data (TX)"><DataDisplay bytes={hubStats.totaltxbytes}/></Item>
    <Item label="Data (RX)"><DataDisplay bytes={hubStats.totalrxbytes}/> 
    </Item>
  </Descriptions>
          
        </Col>
          <Col span={2}></Col>
  {hubStats && <Col span={11}>
           <Descriptions column={2} title="Device Health"  bordered
              extra={<Badge status="success" text="Healthy" />}
              >
                 <Item span={2} label="Hub CPU Usage">
             <Row>
      <Progress type="circle" percent={hubStats.cpu_usage == 400 ? 399 : hubStats.cpu_usage*100/400} size="small" format={percent => `${percent * 4}%`} />
      </Row>
      <Row>
        Note: Quad Core Hub Max CPU Usage = 400%
        </Row>
    </Item>
    <Item span={2} label="Hub RAM Usage">
      <DataDisplayRange complete={(2000000000-hubStats.freememory)} total={2000000000}/>
      <br/>
      <Progress percent={((2000000000-hubStats.freememory)/2000000000*100).toFixed(1)} size="small" />
    </Item>
        <Item span={2} label="Hub free memory">
           <DataDisplay bytes={hubStats.freememory}/>
        </Item>
    <Item span={2} label="Hub Disk Space">
      <DataDisplayRange complete={(hubStats.total_disk_space-hubStats.available_disk_space)} total={hubStats.total_disk_space}/>
      <br/>
      <Progress percent={(((hubStats.total_disk_space-hubStats.available_disk_space))/hubStats.total_disk_space*100).toFixed(1)} size="small" status="active" />
    </Item>
        <Item span={2} label="Hub Free Disk Space">
           <DataDisplay bytes={hubStats.available_disk_space}/>
        </Item>
  <Item span={2} label="Ping-pong latency">{hubStats.pingpongavglatency} msec</Item>
  </Descriptions>
          
        </Col>
}
        </Row> */}
            <Collapse defaultActiveKey={[]}>
          {/* <Panel header="Hub Errors" key="hub_errors">
                <Table 
                  width="100%" 
                  dataSource={dataSource} 
                  columns={columns} 
                  pagination={false}
                  loading={hubErrorsQuery.records.length == 0}
                />
          </Panel>
          <Panel header="Hub Network Events" key="hub_network_events">
        <Table 
          width="100%" 
          dataSource={dataSource1} 
          columns={columns1} 
          pagination={false}
          loading={hubNetworkEventsQuery.records.length == 0}
           />
        </Panel> */}
          <Panel header="Device Analytics" key="qs_subscriber_analytics">
            <QSDashboardGeneric dashboardId={REACT_APP_QS_SUBSCRIBER_HUB_AND_CONTENT_ANALYTICS} hubId={hubId} />
        </Panel>
      </Collapse>
        {props.hasSidenTokenAdminRole && <Divider/>}
         {props.hasSidenTokenAdminRole && <Col style={{marginTop: "20px"}}>
          <Row>
            <Title level={4}>Device Configuration</Title>
          </Row>
            <Row>
            <Collapse defaultActiveKey={[]}>
                <Panel 
                  style={{minWidth: "600px"}}
                  header={`SCE Configuration Policy ID: ${SCEConfigForHub.policyId}`} 
                  key={SCEConfigForHub.policyId}
                  extra={SCEConfigForHub.defaultConfig ? <Tag style={{marginLeft: "20px"}} color="#87d068">Default Configuration</Tag> : <Tag color="#f50" style={{marginLeft: "20px"}}>Custom Configuration</Tag>}
                >
                  <Row justify="space-between">
                    <Button danger onClick={showSCEFormModal}>Apply Custom Configuration</Button>
                    {!SCEConfigForHub.defaultConfig && <Button 
                      type="primary" 
                      onClick={restoreSCEToDefault}
                      loading={SCEConfirmLoading}
                      >Reset To Default</Button>}
                  </Row>
                  <Divider/>
                  <Row>
                    <ReactJson src={SCEConfigForHub}  theme="hopscotch"/>
                  </Row>
                </Panel>
                {/* <Panel 
                  style={{minWidth: "600px"}}
                  header={`UI Configuration Policy ID: ${UIConfigForHub.policyId}`} 
                  key={UIConfigForHub.policyId}
                  extra={UIConfigForHub.defaultConfig ? <Tag color="#87d068">Default Configuration</Tag> : <Tag color="#f50">Custom Configuration</Tag>}
                >
                  <Row justify="space-between">
                    <Button danger onClick={showUIFormModal}>Apply Custom Configuration </Button>
                    {!UIConfigForHub.defaultConfig && <Button type="primary" onClick={restoreUIToDefault}
                    loading={UIConfirmLoading}
                    >Reset To Default</Button>}
                  </Row>
                  <Divider/>
                  <Row>
                    <ReactJson src={UIConfigForHub}  theme="hopscotch"/>
                  </Row>
                </Panel> */}
            </Collapse>
          </Row>
        </Col>}
        <Divider/>
    
        {SCEConfigs && <ModalForm 
            formInstance={SCEForm} 
            visible={SCEFormVisible} 
            onCancel={hideSCEFormModal} 
            options={SCEConfigs} 
            onFormFinish={updateSCEConfig} 
            item="SCE"
            confirmLoading={SCEConfirmLoading}
            setConfirmLoading={setSCEConfirmLoading}
          />}
        {/* {UIConfigs && <ModalForm 
            formInstance={UIForm} 
            visible={UIFormVisible} 
            onCancel={hideUIFormModal} 
            options={UIConfigs} 
            onFormFinish={updateUIConfig} 
            item="UI"
            confirmLoading={UIConfirmLoading}
            setConfirmLoading={setUIConfirmLoading}
          />} */}
    </div>
  );
};

export default DeviceDashboard;

// export default graphql(getQuery, {
//   options: {
//     fetchPolicy: "cache-and-network",
//   },
//   // props: (props) => ({
//   //   posts: props.data.listPosts ? props.data.listPosts.items : [],
//   // }),
// })(HubManagement);
