import React, { useState, useEffect, useContext } from "react";
import Logo from "./img_siden_logo.png";
import "./App.css";

import Amplify, { Auth } from "aws-amplify";
import {
  AmplifyAuthenticator,
  AmplifySignOut,
  AmplifySignIn,
  AmplifyGoogleButton,
  withAuthenticator,
} from "@aws-amplify/ui-react";
// import { getApolloContext } from "react-apollo";
import API, { graphqlOperation } from "@aws-amplify/api";
import { Layout, Menu, Dropdown, Row, Typography, message, notification } from "antd";
import {
  AppstoreOutlined,
  BarChartOutlined,
  CloudOutlined,
  ShopOutlined,
  TeamOutlined,
  UserOutlined,
  LineChartOutlined,
  VideoCameraOutlined,
  SettingOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import SideMenu from "./SideMenu";
import SubscriberManagement from "./SubscriberManagement";
import DebugDashboard from "./DebugDashboard";
import CloudManagement from "./CloudManagement";
import Devices from "./Devices";
import DeviceDashboard from "./DeviceDashboard";
import DashboardConfigs from "./DashboardConfigs";
import SuperAdminPanel from "./SuperAdminPanel";
import {
  BrowserRouter as Router,
  useHistory,
  Route,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";
import Avatar from "antd/lib/avatar/avatar";
import styles from "./header.less";
import SidenHeader from "./SidenHeader";
import ServiceManagement from "./ServiceManagement";
import CellManagement from "./CellManagement";
import VirtualCellManagement from "./VirtualCellManagement";
import Cell from "./Cell";
import { generateAuthHeader, generateSidenToken, getPartnerMNOs, parseJWT } from "./authHeader";
import { API_URL, BUILD_ENV, displayedVersion, HEALTH_URL } from "./constants";

import AWSAppSyncClient, { createAppSyncLink } from "aws-appsync";
// import { ApolloProvider } from "react-apollo";
import { Rehydrated } from "aws-appsync-react"; // this needs to also be installed when working with React
// import ApolloClient from "apollo-client";
import { createHttpLink } from "apollo-link-http";
// import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { CustomRehydrated } from "./Shared/customRehydrated";
import SubscriberDetails from "./SubscriberManagement/SubscriberDetails";
import Home from "./Home";

import SidenLogoDark from "./sidenLogoDark.svg"

import QSDashboardGeneric from "./QSDashboardGeneric";

import {
  REACT_APP_QS_HUB_POPULATION,
  REACT_APP_QS_CELL_USAGE,
  REACT_APP_QS_CELL_SUMMARY,
  REACT_APP_QS_CONTENT_DELIVERY,
  REACT_APP_QS_CONTENT_ENGAGEMENT,
  REACT_APP_QS_SERVICE_OPERATIONS,
  REACT_APP_QS_PRB_REPORT,
  REACT_APP_QS_SERVICE_PROVISIONING,
  REACT_APP_QS_HOME_PAGE
} from "./constants.js"

import { AUTH_REDIRECT_URL } from "./constants.js"

import moment from "moment";
import { requestGet } from "./newApiCallsHelper";
import HubConfigs from "./HubConfigs";

const Background = "https://bszfp9o8s0-flywheel.netdna-ssl.com/wp-content/uploads/2020/11/GettyImages-1196161943.jpg"

const { Title } = Typography
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

const awsmobile = {
  "aws_project_region": "us-east-2",
  "aws_cognito_identity_pool_id": "us-east-2:d8c3242d-4e5e-444d-9ad1-bd44e4cfbe0f",
  "aws_cognito_region": "us-east-2",
  "aws_user_pools_id": "us-east-2_yxFc9IiZQ",
  "aws_user_pools_web_client_id": "223nfp8hkkk46nl6rcsn30lcc0",
  "oauth": {
    "domain": "siden.auth.us-east-2.amazoncognito.com",
    "scope": [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin"
    ],
    "redirectSignIn": AUTH_REDIRECT_URL,
    "redirectSignOut": AUTH_REDIRECT_URL,
    "responseType": "token"
  },
  "federationTarget": "COGNITO_USER_POOLS",
  aws_appsync_graphqlEndpoint:
    "https://sjs7dlwwmrfzve5rmykrvz7d3i.appsync-api.us-east-2.amazonaws.com/graphql",
  aws_appsync_region: "us-east-2",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
};

// Amplify.configure({
//   Auth: {
//     // REQUIRED - Amazon Cognito Identity Pool ID
//     identityPoolId: "us-east-2:d8c3242d-4e5e-444d-9ad1-bd44e4cfbe0f",
//     // REQUIRED - Amazon Cognito Region
//     region: "us-east-2",
//     // OPTIONAL - Amazon Cognito User Pool ID
//     userPoolId: "us-east-2_yxFc9IiZQ",
//     // OPTIONAL - Amazon Cognito Web Client ID
//     userPoolWebClientId: "223nfp8hkkk46nl6rcsn30lcc0",
//   },
//   aws_appsync_graphqlEndpoint:
//     "https://sjs7dlwwmrfzve5rmykrvz7d3i.appsync-api.us-east-2.amazonaws.com/graphql",
//   aws_appsync_region: "us-east-2",
//   aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
//       oauth: {
//       domain: "https://siden-dev.auth.us-east-2.amazoncognito.com"
//     }
// });

Amplify.configure(awsmobile)

const federated = {
  google_client_id: "88222107441-jt74pfb6eopq73pc9iir3i6k27m2vuuh.apps.googleusercontent.com",
}

// const httpLink = createHttpLink({
//   uri:
//     "https://sjs7dlwwmrfzve5rmykrvz7d3i.appsync-api.us-east-2.amazonaws.com/graphql",
// });

// const awsLink = createAppSyncLink({
//   url:
//     "https://sjs7dlwwmrfzve5rmykrvz7d3i.appsync-api.us-east-2.amazonaws.com/graphql",
//   region: "us-east-2",
//   auth: {
//     type: "AMAZON_COGNITO_USER_POOLS",
//     credentials: () => Auth.currentCredentials(),
//     jwtToken: async () =>
//       (await Auth.currentSession()).getAccessToken().getJwtToken(),
//   },
//   complexObjectsCredentials: () => Auth.currentCredentials(),
// });

// const nc9Client = new ApolloClient({
//   link: awsLink.concat(httpLink),
//   cache: new InMemoryCache(),
// });

const client = new AWSAppSyncClient({
  url:
    "https://sjs7dlwwmrfzve5rmykrvz7d3i.appsync-api.us-east-2.amazonaws.com/graphql",
  region: "us-east-2",
  auth: {
    type: "AMAZON_COGNITO_USER_POOLS",
    // apiKey: AppSyncConfig.apiKey,
    jwtToken: async () =>
      (await Auth.currentSession()).getAccessToken().getJwtToken(), // Required when you use Cognito UserPools OR OpenID Connect. token object is obtained previously
  },
});

// let token = await Auth.currentSession().then((session) =>
//   session.getIdToken().getJwtToken()
// ); // Cognito
// let client = new ApolloClient({
//   // uri: awsconfig.aws_appsync_graphqlEndpoint,
//   uri:
//     "https://sjs7dlwwmrfzve5rmykrvz7d3i.appsync-api.us-east-2.amazonaws.com/graphql",
//   headers: {
//     Authorization: async () =>
//       (await Auth.currentSession()).getAccessToken().getJwtToken(),
//   },
//   // headers: { Authorization: token },
// });

// const Rehydrated = ({ children }) => {
//   const { client } = useContext(getApolloContext());
//   const [rehydrated, setState] = useState(false);

//   useEffect(() => {
//     if (client instanceof AWSAppSyncClient) {
//       (async () => {
//         await client.hydrated();
//         setState(true);
//       })();
//     }
//   }, [client]);
//   return rehydrated ? <>{children}</> : null;
// };

const onMenuClick = ({ key, keyPath, item, domEvent }) => {
  // const history = useHistory();

  if (key === "logout") {
    Auth.signOut();
    return;
  }

  console.log(`/account/${key}`);
  // history.push(`/account/${key}`);
};

function InnerApp() {
  const defaultListofMNOs = [
    "m17641935",
    "m17641936",
    "m17641937",
    "m17641938",
    "m17641939",
    "m111111",
    "test_mno",
  ].map(v=> ({alias: v, mnoId: v}))
  console.log(defaultListofMNOs)

  const [systemStatus, setSystemStatus] = useState({stopped: true});
  const [collapsed, setCollapsed] = useState();
  const [user, setUser] = useState(null);
  const [sidenToken, setSidenToken] = useState({})
  const [partnerMNOs, setPartnerMNOs] = useState(defaultListofMNOs)

  const checkHealth = () => {
    console.log("Health Check Heartbeat");
    console.log({systemStatus})
    return requestGet(`${API_URL}/api/v1/dc/delivery`).then(result=> setSystemStatus(result.data))
  };

  const getPartnerMNOList = () => {
    getPartnerMNOs().then(partnerMNOs=> {
      console.log({aaaaassssss: partnerMNOs})
      if (partnerMNOs.length == 0){
        setPartnerMNOs(defaultListofMNOs)
      } else {
        setPartnerMNOs(partnerMNOs)
      } 
    })
  };

  const setMNOTenant = (mnoId) => {
    console.log({mnoId})
    localStorage.setItem("selectedMNOId",mnoId)
    checkHealth().then(
      result => {
        message.success(`Changed MNO Tenant to ${mnoId}, Reloading`)
      },
      error => {
        message.error(`Error on Selecting MNO ${mnoId}`)
        localStorage.removeItem("selectedMNOId")
      }
    ).then(v => setTimeout(v=> window.location.reload(),1500))
  }

  const selectedMNOId = localStorage.getItem("selectedMNOId")

  console.log({selectedMNOId})
  console.log({partnerMNOs})


  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => setUser(user));
  }, []);

  const checkTokenExpiration = () => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (!user || (user.signInUserSession.idToken.payload.exp * 1000) - moment.now() < 60000) {
          message.error("Token Expired")
          Auth.signOut()
        }
      }).catch(
        e => {
          if (e == "not authenticated") {
            Auth.signOut()
          }
        })
  };

  useEffect(() => {
    checkHealth();
  }, []);

  useEffect(() => {
    getPartnerMNOList();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      message.error("Token Expired")
      Auth.signOut()
    }, 3600000);
    return () => clearTimeout(timer);
  }, []);


  useEffect(() => {
    checkTokenExpiration();
  });

  useEffect(() => {
    generateSidenToken().then(token=> setSidenToken(token))
  }, [])

  const parsedSidenToken = parseJWT(sidenToken.jwt)

  const hasSidenTokenAdminRole = parsedSidenToken ? parsedSidenToken["https://siden.io/role"] == "admin" : false

  console.log({parsedSidenToken})

  const userGroups = user
    ? user.signInUserSession.idToken.payload["cognito:groups"]
    : [];

  const sidenServiceAdmin =
    (userGroups || []).includes("siden_service_admin") || false;

  const contentProviderGroup =
    (userGroups || []).includes("content_provider_group") || false;

  const sidenEmployee =
    (userGroups || []).includes("siden_employee_group") || false;

  const qATestingGroup =
    (userGroups || []).includes("qa_testing_group") || false;

  if (!user) {
    return null
  }

  if (contentProviderGroup) {
    return (
      <Router>
        <Layout style={{ minHeight: '100vh' }}>
          <Sider
            collapsible collapsed={collapsed} onCollapse={() => setCollapsed(!collapsed)}
          // style={{
          //   overflow: "auto",
          //   height: "100vh",
          //   position: "fixed",
          //   left: 0,
          // }}
          >
            <SideMenu collapsed={collapsed} userGroups={userGroups} />
          </Sider>
          <Layout className="site-layout">
            {/* <SidenHeader selectedMNOId={selectedMNOId} setMNOTenant={setMNOTenant} /> */}
            <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
              <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <Switch>
                  <Route path="/content_engagement">
                    <QSDashboardGeneric key={`${REACT_APP_QS_CONTENT_ENGAGEMENT}-qs-route`} dashboardId={REACT_APP_QS_CONTENT_ENGAGEMENT} />
                  </Route>
                  <Redirect from="/home" to="/content_engagement" />
                  <Redirect from="/" to="/content_engagement" />
                </Switch>
              </div>
            </Content>
            <Footer style={{ textAlign: "right" }}>
              Version: {displayedVersion}
            </Footer>
          </Layout>
        </Layout>
      </Router>
    );
  }


  // https://siden-dev.auth.us-east-2.amazoncognito.com/login?response_type=code&client_id=223nfp8hkkk46nl6rcsn30lcc0&redirect_uri=http://localhost:3000/
  return (
    <Router>
      <Layout style={{ minHeight: '100vh' }}>
        <Sider
          collapsible collapsed={collapsed} onCollapse={() => setCollapsed(!collapsed)}
        // style={{
        //   overflow: "auto",
        //   height: "100vh",
        //   position: "fixed",
        //   left: 0,
        // }}
        >
          <SideMenu collapsed={collapsed} userGroups={userGroups} />
        </Sider>
        <Layout className="site-layout">
          <SidenHeader selectedMNOId={selectedMNOId} setMNOTenant={setMNOTenant} partnerMNOs={partnerMNOs} hasSidenTokenAdminRole={hasSidenTokenAdminRole}/> 
          <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
              <Switch>
                <Route path="/subscribers/:sidenAccountId">
                  <SubscriberDetails debugMode key="subscriber-details" />
                </Route>
                {sidenEmployee && <Route path="/subscribers">
                  <SubscriberManagement debugMode vpnStatus={true} key="subscriber-management" />
                </Route>}
                <Route path="/devices_dashboard">
                  <QSDashboardGeneric key={`${REACT_APP_QS_HUB_POPULATION}-qs-route`} dashboardId={REACT_APP_QS_HUB_POPULATION} mnoId={selectedMNOId}/>
                </Route>
                <Route path="/network_cells_cells_usage">
                  <QSDashboardGeneric key={`${REACT_APP_QS_CELL_USAGE}-qs-route`} dashboardId={REACT_APP_QS_CELL_USAGE} mnoId={selectedMNOId}/>
                </Route>
                <Route path="/network_cells_cells_summary">
                  <QSDashboardGeneric key={`${REACT_APP_QS_CELL_SUMMARY}-qs-route`} dashboardId={REACT_APP_QS_CELL_SUMMARY} mnoId={selectedMNOId}/>
                </Route>
                {/* <Route path="/network_cells_service_provisioning">
                  <QSDashboardGeneric key={`${REACT_APP_QS_SERVICE_PROVISIONING}-qs-route`} dashboardId={REACT_APP_QS_SERVICE_PROVISIONING} />
                </Route> */}
                <Route path="/network_content_delivery">
                  <QSDashboardGeneric key={`${REACT_APP_QS_CONTENT_DELIVERY}-qs-route`} dashboardId={REACT_APP_QS_CONTENT_DELIVERY} mnoId={selectedMNOId}/>
                </Route>
                <Route path="/device_status/:hubId">
                  <DeviceDashboard vpnStatus={true} key="device-status" hasSidenTokenAdminRole={hasSidenTokenAdminRole} />
                </Route>
                <Route path="/device_status">
                  <Devices vpnStatus={true} hasSidenTokenAdminRole={hasSidenTokenAdminRole} key="devices" />
                </Route>
                <Route path="/service_operation">
                  <QSDashboardGeneric key={`${REACT_APP_QS_SERVICE_OPERATIONS}-qs-route`} dashboardId={REACT_APP_QS_SERVICE_OPERATIONS} mnoId={selectedMNOId}/>
                </Route>
                {/* <Route path="/content_engagement">
                  <QSDashboardGeneric key={`${REACT_APP_QS_CONTENT_ENGAGEMENT}-qs-route`} dashboardId={REACT_APP_QS_CONTENT_ENGAGEMENT} />
                </Route> */}
                <Route path="/service*">
                  <ServiceManagement
                    status={systemStatus}
                    checkHealth={checkHealth}
                    vpnStatus={true}
                  />
                </Route>
                <Route exact path="/service_management">
                  <Redirect to="/service_management/service" />
                </Route>


                <Route path="/siden_cloud_management">
                  <CloudManagement />
                </Route>
                <Route path="/network_cells_service_prb_reports">
                  <QSDashboardGeneric key={`${REACT_APP_QS_PRB_REPORT}-qs-route`} dashboardId={REACT_APP_QS_PRB_REPORT} mnoId={selectedMNOId}/>
                </Route>
                <Route path="/cell_management/:cellId">
                  <Cell vpnStatus={true} key="cell" />
                </Route>
                <Route exact path="/cell_management">
                  <CellManagement vpnStatus={true} debugMode key="cell-management" />
                </Route>
                {sidenEmployee && <Route path="/debug_service_analytics">
                  <DebugDashboard />
                </Route>}
                {sidenEmployee && <Route path="/debug_virtual_cells/:cellId">
                  <Cell vpnStatus={true} debugMode key="cell-virtual" />
                </Route>}
                {sidenEmployee && <Route exact path="/debug_virtual_cells">
                  <VirtualCellManagement vpnStatus={true} debugMode key="cell-management-virtual" />
                </Route>}
                {sidenEmployee && <Route path="/debug_subscribers/:sidenAccountId">
                  <SubscriberDetails debugMode key="subscriber-details-virtual" />
                </Route>}
                {sidenEmployee && <Route path="/debug_devices/:hubId">
                  <DeviceDashboard vpnStatus={true} debugMode hasSidenTokenAdminRole={hasSidenTokenAdminRole} key="device-status-virtual" />
                </Route>}
                {sidenEmployee && <Route exact path="/debug_subscribers">
                  <SubscriberManagement vpnStatus={true} debugMode key="subscriber-management-virtual" />
                </Route>}
                {sidenEmployee && <Route exact path="/debug_devices">
                  <Devices vpnStatus={true} debugMode hasSidenTokenAdminRole={hasSidenTokenAdminRole} key="devices-virtual" />
                </Route>}
                {sidenEmployee && <Route exact path="/hub_configurations">
                  <HubConfigs />
                </Route>}
                {(sidenEmployee || qATestingGroup) && <Route exact path="/dashboard_status">
                  <DashboardConfigs user={user} />
                </Route>}
                {(sidenEmployee) && <Route exact path="/super_admin_panel">
                  <SuperAdminPanel user={user} />
                </Route>}
                <Redirect from="/home" to="/" />
                <Route exact path="/">
                  <QSDashboardGeneric key={`${REACT_APP_QS_HOME_PAGE}-qs-route`} dashboardId={REACT_APP_QS_HOME_PAGE} title="Welcome to the Siden Dashboard!" mnoId={selectedMNOId}/>
                </Route>
              </Switch>
            </div>
          </Content>
          <Footer style={{ textAlign: "right" }}>
            Version: {displayedVersion}
          </Footer>
        </Layout>
      </Layout>
    </Router>
  );
}

const WrappedApp = withAuthenticator(InnerApp)

function App() {
  return (
    <WrappedApp>
      <div slot="sign-in" style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100vw',
        backgroundImage: `url(${Background})`,
        backgroundSize: '1920px 100vh',
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundPosition: "center"
      }}>
        <AmplifySignIn
          headerText={"Welcome to the Siden Dashboard"}
          // logo={<img src={SidenLogoDark} alt="logo"/>}
          slot="sign-in"
          formFields={[{ type: "hidden" }]}
          hideSignUp
          hideForgotPassword
        >
          <amplify-google-button slot="federated-buttons" onClick={() => Auth.federatedSignIn({ provider: 'Google' })} className="sign-in-button" />
          {BUILD_ENV === "prd" && <amplify-auth0-button slot="federated-buttons" onClick={() => Auth.federatedSignIn({ provider: 'Auth0' })} className="sign-in-button" />}
          { BUILD_ENV === "stg" && <amplify-auth0-button slot="federated-buttons" onClick={() => Auth.federatedSignIn({ provider: 'StgAuth0' })} className="sign-in-button" /> }
          { BUILD_ENV === "dev" && <amplify-auth0-button slot="federated-buttons" onClick={() => Auth.federatedSignIn({ provider: 'DevAuth0' })} className="sign-in-button" /> }
          { BUILD_ENV === "dev" && <amplify-oauth-button slot="federated-buttons" onClick={() => Auth.federatedSignIn()} className="sign-in-button" /> }
          <span slot="footer"></span>
        </AmplifySignIn>

      </div>
    </WrappedApp>)
}

export default App;
