import React, { useState } from 'react';
import { Button, Modal, Form, Input, Radio } from 'antd';
import { REACT_APP_QS_PRB_REPORT } from '../constants';

interface Values {
  title: string;
  description: string;
  modifier: string;
}

interface HubEditFormProps {
  visible: boolean;
  onEdit: (values: Values) => void;
  onCancel: () => void;
}

const HubEditForm: React.FC<HubEditFormProps> = ({
  visible,
  onEdit,
  onCancel,
  account,
  debugMode,
}) => {
  const [form] = Form.useForm();

  console.log({account})
  return (
    <Modal
      visible={visible}
      title="Edit Subscriber"
      okText="Save"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then(values => {
            onEdit(values);
          })
          .catch(info => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={account}
      >
        <Form.Item 
            name="mnoAccountId" 
            label="MNO Account ID (MSISDN)" 
            rules={[
                  {
                    required: true,
                    message: 'Please input the MNO Account ID (MSISDN)!',
                  },
                ]}>
                <Input />
            </Form.Item>
        <Form.Item 
                name="iccid" 
                label="ICCID" 
                rules={[
                  {
                    // required: true
                    message: 'Please input the ICCID',
                  },
                ]}>
            <Input />
        </Form.Item>
        {/* <Form.Item 
                name="imsi" 
                label="imsi" 
                rules={[
                  {
                    // required: true
                    message: 'Please input the imsi',
                  },
                ]}>
            <Input />
        </Form.Item> */}
        {debugMode && <React.Fragment>
                
            <Form.Item name="mnoAlias" label="MNO Alias"
            rules={[
                  {
                    required: true,
                    message: 'Please input the MNO Alias',
                  },
                ]}>

              <Input />
            </Form.Item>
            <Form.Item
                name="deviceType"
              label="Device Type"
              className="collection-create-form_last-form-item"
            >
          
                <Radio.Group>
                  <Radio value="VIRTUAL">Virtual</Radio>
                  <Radio value="VIRTUAL_SIM">Virtual Sim</Radio>
                  {/* <Radio value="REAL">Real</Radio> */}
                </Radio.Group>
            
            </Form.Item>
            <Form.Item name="deviceVersion" label="Device Version">
           <Input />
            </Form.Item>    
            <Form.Item name="sceHostname" label="SCE Host Name">
           <Input />
            </Form.Item>    
            
        </React.Fragment>}
      </Form>
    </Modal>
  );
};

// const  = () => {
//   const [visible, setVisible] = useState(false);

//   const onEdit = (values: any) => {
//     console.log('Received values of form: ', values);
//     setVisible(false);
//   };

//   return (
//     <div>
//       <Button
//         type="primary"
//         onClick={() => {
//           setVisible(true);
//         }}
//       >
//         New Collection
//       </Button>
//       <HubEditForm
//         visible={visible}
//         onEdit={onEdit}
//         onCancel={() => {
//           setVisible(false);
//         }}
//       />
//     </div>
//   );
// };

export default HubEditForm;